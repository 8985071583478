import { registerApplication, start } from "single-spa";
import { authService } from "@talygen/authservice";
import initAuthService from "./initAuthService";
import * as prefix from "./activity-functions";
if (
  //!localStorage.getItem("oidc.user:https://devlogin.talygen.devlabs.com:talygenspa")
  //!localStorage.getItem("oidc.user:https://applogin.talygen.com:talygenspa")
  !localStorage.getItem("oidc.user:https://cloudlogin.talygen.com:talygenspa")
) {
  if (
    location.pathname == "/DownloadPublicFile" ||
    location.pathname == "DownloadPublicFolder"
  ) {
    registerApplication({
      name: "@talygen/docstorage",
      app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/DocStorage/dist/app.js"),
      activeWhen: (location) =>
        prefix.prefix(location, ["DownloadPublicFile", "DownloadPublicFolder"]),
      customProps: { userProfile: userProfileInfo },
    });
  } else {
    initAuthService(location.pathname);
    var userProfileInfo = null;
    authService.getUser().then(function (response) {
      userProfileInfo = response;
      registerApplication({
        name: "@talygen/navbar",
        app: () => System.import("@talygen/navbar"),
        activeWhen: "/",
        customProps: { userProfile: userProfileInfo },
      });
      registerApplication({
        name: "@talygen/crm",
        app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/CRM/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "CRM",
            "Brand",
            "CommunicationModes",
            "CRMReport",
            "User",
            "logout",
          ]),
        customProps: { userProfile: userProfileInfo },
      });
      registerApplication({
        name: "@talygen/expenses",
        app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/Expense/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "Expenses",
            "ExpenseApproval",
            "ExpenseReport",
            "ExpensesHistory",
          ]),
        customProps: { userProfile: userProfileInfo },
      });
      registerApplication({
        name: "@talygen/docstorage",
        app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/DocStorage/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "File",
            "ViewFileDetails",
            "RecyleBin",
            "Storage",
            "MyDashboard",
            "ViewFolderDetails",
            "AdvancedSetup",
            "UserStorageReport",
            "UserStorage",
            "ManageTags",
            "DownloadPublicFile",
            "DownloadPublicFolder",
          ]),
        customProps: { userProfile: userProfileInfo },
      });
      
      registerApplication({
        name: "@talygen/assetmanagement",
        app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/AssetManagement/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [ 
          "Asset",
          "AssetAssignment",
          "AssetRequisition",
          "AssetCatalog",
          "AssetReport",
          "AdvancedSetups",
          ]), 
        customProps: { userProfile: userProfileInfo },
      });
      registerApplication({
        name: "@talygen/commonui",
        app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/CommonUi/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "HelpAndGuide",
            "HelpArticles",
            "UserManual",
            "HelpArticlesPerModule",
            "VideoTutorials",
            "Report",
            "SetupGuides",
          ]),
        customProps: { userProfile: userProfileInfo },
      });
      // registerApplication({
      //   name: "@talygen/approvalmodule",
      //   app: () => System.import("http://localhost:9010/app.js"),
      //   activeWhen: (location) => prefix.prefix(location, ["ApprovalRule"]),
      //   customProps: { userProfile: userProfileInfo },
      // });
    });
  }
} else {
  var userProfileInfo = JSON.parse(
    // localStorage.getItem("oidc.user:https://devlogin.talygen.devlabs.com:talygenspa")
    //  localStorage.getItem("oidc.user:https://applogin.talygen.com:talygenspa")
    localStorage.getItem("oidc.user:https://cloudlogin.talygen.com:talygenspa")
  );
  registerApplication({
    name: "@talygen/navbar",
    app: () => System.import("@talygen/navbar"),
    activeWhen: "/",
    customProps: { userProfile: userProfileInfo },
  });
  registerApplication({
    name: "@talygen/crm",
    app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/CRM/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "CRM",
        "Brand",
        "CommunicationModes",
        "CRMReport",
        "User",
        "logout",
      ]),
    customProps: { userProfile: userProfileInfo },
  });
  registerApplication({
    name: "@talygen/expenses",
    app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/Expense/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "Expenses",
        "ExpenseApproval",
        "ExpenseReport",
        "ExpensesHistory",
      ]),
    customProps: { userProfile: userProfileInfo },
  });
  registerApplication({
    name: "@talygen/docstorage",
    app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/DocStorage/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "File",
        "ViewFileDetails",
        "RecyleBin",
        "Storage",
        "MyDashboard",
        "ViewFolderDetails",
        "AdvancedSetup",
        "UserStorageReport",
        "UserStorage",
        "ManageTags",
        "DownloadPublicFile",
        "DownloadPublicFolder",
      ]),
    customProps: { userProfile: userProfileInfo },
  });
  // registerApplication({
  //   name: "@talygen/ticketing",
  //   app: () => System.import("http://localhost:9006/app.js"),
  //   activeWhen: (location) =>
  //     prefix.prefix(location, ["FSM", "Ticketing", "RuleEngine"]),
  //   customProps: { userProfile: userProfileInfo },
  // });
  registerApplication({
    name: "@talygen/assetmanagement",
    app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/AssetManagement/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "Asset",
        "AssetAssignment",
        "AssetRequisition",
        "AssetCatalog",
        "AssetReport",
        "AdvancedSetups",
      ]),
    customProps: { userProfile: userProfileInfo },
  });
  registerApplication({
    name: "@talygen/commonui",
    app: () => System.import("https://talygenv.github.io/talygenui/UsicCloud/CommonUi/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "HelpAndGuide",
        "HelpArticles",
        "UserManual",
        "HelpArticlesPerModule",
        "VideoTutorials",
        "Report",
        "SetupGuides",
      ]),
    customProps: { userProfile: userProfileInfo },
  });
  // registerApplication({
  //   name: "@talygen/timer",
  //   app: () => System.import("http://localhost:9009/app.js"),
  //   activeWhen: (location) => prefix.prefix(location, ["TimeTracking"]),
  //   customProps: { userProfile: userProfileInfo },
  // });
  // registerApplication({
  //   name: "@talygen/approvalmodule",
  //   app: () => System.import("http://localhost:9010/app.js"),
  //   activeWhen: (location) => prefix.prefix(location, ["ApprovalRule"]),
  //   customProps: { userProfile: userProfileInfo },
  // });
}
start();